import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SoldCarsPage = ({data}) => (
  <>
  <Layout>
    <Seo title="Revive autod" />
    <section id="carsofsale">
    <div className="product-album py-4 py-lg-5">
      <div className="container">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 g-md-5">
            {data.allCarsJson.nodes.map(post => (
            <div className="col" key={post.id}>
                <div className="card">
                <Link to={`/car/${post.id}/`}>
                <img className="card-img-top" src={post.image} alt="" width="560px"/>
                </Link>
                    <div className="card-body">
                      <Link className="card-tile" to={`../car/${post.id}/`}><h2>{post.title}</h2></Link>
                      <span className="card-price">MÜÜDUD</span>
                    </div>
              </div>
            </div>
            ))}
          </div>
        </div>
        </div>
        </section>
  </Layout>
  </>
)

export const query = graphql`
query SoldCarQuery {
  allCarsJson(filter: {sold: {eq: true}}) {
    nodes {
      id
      image
      title
      price
    }
  }
}
`

export default SoldCarsPage
